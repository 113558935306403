import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  change(event) {
    let form = event.target.closest('form')
    let fd = new FormData(form)

    let url = new URL(this.urlValue)
    url.search = new URLSearchParams(fd)

    get(url, {
      responseKind: 'turbo-stream'
    })
  }
}
